function getSspId(bidParams) {
	const keys = Object.keys(bidParams);
	const getMatches = (names) => {
		const res = [];
		names.forEach((name) => {
			keys.forEach((key) => {
				if (key.toLowerCase().indexOf(name) >= 0) {
					res.push(key);
				}
			});
		});
		return res;
	};
	let matches = getMatches(['placement', 'slot', 'zone', 'unit', 'inventorycode']);

	if (matches.length === 0) {
		matches = getMatches(['id']);
	}
	return matches.length === 1 ? bidParams[matches[0]] : null;
}

module.exports = { getSspId };
