const Utils = require('./utils');
/**
 * "Slot" object used to somewhat mimic googletag.Slot
 */
class DivSlot {
	constructor(settings) {
		Utils.assign(this, settings);
	}

	static getOrCreateSlot(divId, adUnitPath, otherParams, alwaysCreate) {
		let slot = DivSlot.idToSlot[divId];
		if (!slot || alwaysCreate || (adUnitPath && slot.adUnitPath !== adUnitPath)) {
			slot = new this({ divId, adUnitPath, ...otherParams });
			DivSlot.idToSlot[divId] = slot;
		}
		return slot;
	}

	static list() {
		return Utils.values(DivSlot.idToSlot).filter((s) => s instanceof this);
	}

	getAdUnitPath() {
		return this.adUnitPath;
	}

	getSlotElementId() {
		return this.divId;
	}

	setCollapseEmptyDiv(collapse, collapseBeforeFetch) {
		Utils.assign(this, { collapse, collapseBeforeFetch });
	}
}

DivSlot.idToSlot = {};

module.exports = DivSlot;
