const NATIVE_DATA_ID_MAP = {
	sponsoredBy: 1,
	body: 2,
	rating: 3,
	likes: 4,
	downloads: 5,
	price: 6,
	salePrice: 7,
	phone: 8,
	address: 9,
	body2: 10,
	displayUrl: 11,
	cta: 12,
};

const NATIVE_IMG_ID_MAP = {
	icon: 1,
	image: 3,
};

const NATIVE_IMAGE_FIELDS = Object.keys(NATIVE_IMG_ID_MAP);

const RTB_FIELDS = [
	'title',
	...NATIVE_IMAGE_FIELDS,
	...Object.keys(NATIVE_DATA_ID_MAP),
];

const NATIVE_FIELDS = [
	...RTB_FIELDS,
];

module.exports = {
	NATIVE_DATA_ID_MAP,
	NATIVE_IMG_ID_MAP,
	NATIVE_FIELDS,
	NATIVE_IMAGE_FIELDS,
	RTB_FIELDS,
	TITLE_DEF_LEN: 1000,
};
