const DivSlot = require('./divSlot');

/**
* Fake slot
*/
class VideoSlot extends DivSlot {
	constructor(params) {
		super({
			noRefresh: true,
			...params,
		});
	}
}

module.exports = VideoSlot;
