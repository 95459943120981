const Utils = require('./utils');

/**
 * This class maps 1-to-1 to the Prebid ad units. For example, if we have a page that looks like this:
 *
 * <div data-ad-unit-id="/12345/top-banner"><div>
 * <div data-ad-unit-id="/12345/top-banner"><div>
 *
 * Then there will be one AdUnit object in the auction, but two AdUnitInstance objects,
 * both referring to the same AdUnit.
 */
class AdUnitInstance {
	constructor({
		adUnit, slot, code, auction,
	}) {
		Utils.assign(this, {
			pbAdUnit: {
				...Utils.clone(adUnit.pbAdUnit),
				code,
				__slot: slot,
			},
			finalPbAdUnits: [], // To be set later, pbAdUnit might be split into multiple units
			adUnit,
			slot,
			code,
			auction,
		});
		const { adserver } = this;
		if (adserver.adsRtEnabled) {
			const path = adserver.normalizePathFromSlot(slot);
			this.adserverBid = {
				path,
				bidder: this.adserver.id,
				__rlvId: `${adUnit.placementId}-${path}`,
				adUnitCode: code,
				code,
				cpm: 0,
				bidId: Utils.generateUUID(),
				params: { type: this.adserver.type },
				auctionId: this.auction.auctionId,
				isAdserverBid: true,
			};
		}
	}

	remove() {
		this.auction.removeUnitData(this);
	}

	get adserver() {
		return this.adUnit.adserver;
	}

	getHighestBid() {
		return this.auction.pbjsCall('getHighestCpmBids', [this.code])?.[0];
	}
}

module.exports = AdUnitInstance;
