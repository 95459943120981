/* eslint-disable no-underscore-dangle */
const Utils = require('./utils');
const VideoSlot = require('./videoSlot');
const RequestAuction = require('./requestAuction');
const { AD_REQUESTING_RUNNING, WAITING, RUNNING } = require('./constants');
const { s2sBidderSuffix } = require('./sharedConstants');
const AdserverTypes = require('./adserverTypes');
const AdserverBase = require('./adserverBase');
const BidderHandler = require('./bidderHandler');
const pbjsInject = require('./pbjsInject');

const { LazyLoader, Optimization, Reloader } = window.relevantDigital.exports;

let log;

const EXPORT_FNS = [
	'loadPrebid',
	'addPrebidConfig',
	'addAmazonConfig',
	'aliasBidder',
	'defineVideoSlots',
	'loadVideoUrls',
	'registerRenderedDivId',
	'getConfigs',
	'getAdUnitInstanceByBid',
	'provideObject',
	'addAuctionCallbacks',
	'loadGeoWithTimeout',
	'getBidderHandler',
];

const CMP_APIS = {
	tcf: {
		api: '__tcfapi',
		fn: (cb) => window.__tcfapi('addEventListener', 2, (param, success) => {
			const tc = param || {};
			const notNeeded = tc.gdprApplies === false;
			if (!success || tc.eventStatus === 'tcloaded' || tc.eventStatus === 'useractioncomplete' || notNeeded) {
				cb({ data: tc, success, notNeeded });
			}
		}),
	},
};

class PrebidRequester {
	constructor(FIELD, SITE) {
		let s2sConfig;
		if (SITE.s2sAliases && Object.keys(SITE.s2sAliases).length > 0) {
			s2sConfig = {
				accountId: '1',
				adapter: 'prebidServer',
				adapterOptions: {},
				enabled: true,
				endpoint: `${FIELD.pbsUrl}/openrtb2/auction`,
				bidders: Object.keys(SITE.s2sAliases),
				syncEndpoint: `${FIELD.pbsUrl}/cookie_sync`,
				extPrebid: {
					aliases: SITE.s2sAliases,
				},
			};
		}
		// Add price granularity data
		let priceGranularity;
		if (FIELD.priceGranularity === 'custom') {
			priceGranularity = {
				buckets: FIELD.customPriceGranularityRanges
					.map(({ max, increment }) => ({
						precision: FIELD.customPriceGranularityPrecision,
						max,
						increment,
					})),
			};
		} else if (FIELD.priceGranularity !== 'medium') {
			priceGranularity = FIELD.priceGranularity;
		}
		Utils.assign(this, FIELD, SITE, {
			cmpData: {},
			storage: Utils.storage(),
			pendingAuctions: [],
			doneAuctions: [],
			initAuctionCount: 0, // auctions currently being initialized
			initAuctionListeners: [],
			auctionDoneListeners: [],
			loadOnceState: {},
			hasPbServer: !!s2sConfig,
			cbSet: Utils.callbackSet(),
			msgCallbacks: Utils.callbackSet(),
			msgHandlers: {},
			renderedDivs: {}, // Keep track of "adserver-rendered" ad-divs, used for reloading
			rands: {},
			prebidConfig: {
				rubicon: { singleRequest: true },
				improvedigital: { singleRequest: true },
				consentManagement: {},
				instreamTracking: { enabled: true, maxWindow: 3600000 },
				enableTIDs: true,
				floors: {},
				...(s2sConfig && { s2sConfig }),
				cache: {
					url: 'https://prebid.adnxs.com/pbc/v1/cache',
				},
				userSync: {
					syncDelay: 1000,
				},
				...(priceGranularity && { priceGranularity }),
			},
			amazonConfig: {},
			providedObjects: {},
			rlvInfoByBid: {},
			domInterface: document,
		});
		window.addEventListener('message', (ev) => {
			if (ev.data?.type === 'rlvMessage') {
				this.msgHandlers[ev.data.command]?.(ev);
			}
		});
		this.setConfigCurrency(this.adServerCurrency);
		this.loadGeo = this.storage.urlCaches.onceLoader({
			url: `${this.analyticsURL}/analytics/geo`,
			storageKey: 'geo',
			dataSubKey: 'country',
			noReloadMs: 4 * 3600 * 1000,
			transform: (r) => r?.query?.country,
		});
		this.initPbjsTimeout();
		this.pbjs = window[this.pbjsName] = window[this.pbjsName] || {};
		this.pbjs.que = this.pbjs.que || [];
		if (this.loadPbjs) {
			Utils.loadScript(this.pbjsURL);
		}
		this.reloader = Reloader && new Reloader({ pbRequester: this });
		this.lazyLoader = LazyLoader && new LazyLoader({ pbRequester: this });
		this.optimization = Optimization && new Optimization(this);
		// Ensure that updated msgHandlers are applied (possibly added by reloader, lazyloader, opti ^)
		this.msgCallbacks.apply(this.msgHandlers);
	}

	setConfigCurrency(adServerCurrency) {
		const { exchangeRates } = this;
		if (!adServerCurrency || !exchangeRates[adServerCurrency]) {
			return;
		}
		const conversions = {};
		// eslint-disable-next-line guard-for-in
		for (const currency in exchangeRates) {
			conversions[currency] = exchangeRates[currency] / exchangeRates[adServerCurrency];
		}
		const rates = { [adServerCurrency]: conversions };
		// Don't stall prebid when supplying rates in old versions (https://github.com/prebid/Prebid.js/pull/10736)
		const hasBug = this.pbjsVer && this.pbjsVer[0] <= 8 && this.pbjsVer[1] <= 25;
		this.addPrebidConfig({
			currency: { adServerCurrency, rates: hasBug ? undefined : rates	},
		});
	}

	currencyConvert(cpm, from, to) {
		const { exchangeRates } = this;
		const fromRate = exchangeRates[from || 'USD'];
		const toRate = exchangeRates[to || 'USD'];
		if (!fromRate || !toRate || fromRate === toRate) {
			return cpm;
		}
		return cpm * (toRate / fromRate);
	}

	initPbjsTimeout() {
		const { pbsRoundTripBufferMs: bufferMs, pbjsTimeout } = this;
		const { s2sConfig } = this.prebidConfig;
		if (s2sConfig) {
			s2sConfig.timeout = Math.min(Math.max(pbjsTimeout - bufferMs, bufferMs), pbjsTimeout);
		}
		this.prebidConfig.bidderTimeout = pbjsTimeout;
		this.amazonConfig.bidTimeout = pbjsTimeout;
	}

	addAuctionCallbacks(cbs, settings) {
		this.cbSet.add(cbs, { ...settings, type: 'auction' });
	}

	addLowLevelRlvBidResponseInfo(bid) {
		if (bid.ext?.relevant && bid.ext.prebid?.bidid) {
			this.rlvInfoByBid[bid.ext.prebid?.bidid] = bid.ext.relevant;
		}
	}

	getRlvResponseInfo(bid) {
		if (bid.pbsBidId) {
			return this.rlvInfoByBid[bid.pbsBidId];
		}
		return null;
	}

	getCmpData(framework, cb) {
		const { fn, api } = CMP_APIS[framework] || {};
		const getObj = () => this.cmpData[framework];
		const getData = () => {
			const obj = getObj();
			return obj && !obj.notNeeded ? obj.data : undefined;
		};
		const respond = () => cb?.(getData(), !!getObj()?.success);
		if (!api || typeof window[api] !== 'function') {
			respond();
			return getData();
		}
		if (!getObj()) {
			const obj = { ev: Utils.onceEvent() };
			this.cmpData[framework] = obj;
			fn((res) => {
				Utils.assign(obj, res);
				obj.ev.trigger();
			});
		}
		if (cb) {
			getObj().ev.wait(respond);
		}
		return getData();
	}

	/**
	 * Load a js file only once,
	 * i.e make sure it wont load again if function is called multiple times
	 */
	loadOnce(jsUrl, cb) {
		let state = this.loadOnceState[jsUrl];
		if (!state) {
			state = { queue: [cb] };
			const onDone = () => {
				state.done = true;
				state.queue.forEach((fn) => fn(state));
				delete state.queue;
			};
			this.loadOnceState[jsUrl] = state;
			const scr = document.createElement('script');
			scr.onload = onDone;
			scr.onerror = function () {
				state.error = true;
				onDone();
			};
			scr.src = jsUrl;
			document.head.appendChild(scr);
		} else if (!state.done) {
			state.queue.push(cb);
		} else {
			cb(state);
		}
	}

	/**
	 * adId === null => we know there was no adserver targeting set
	 * adId === undefined => we don't know, let's find out
	 */
	registerRenderedDivId(divId, adId) {
		this.renderedDivs[divId] = this.domInterface.getElementById(divId);
		const { relevantDigital } = window;

		// Find the oldest auction where the div-id has been used and not yet marked as 'renderDone'
		for (const auction of this.allAuctions()) {
			const data = Utils.find(auction.usedUnitDatas, (unitData) => (
				unitData.slot.getSlotElementId() === divId && !unitData.renderDone
			));
			if (data) {
				data.renderDone = true;
				if (adId !== null) {
					let responses = auction.pbjsCall('getBidResponsesForAdUnitCode', data.code)?.bids || [];
					if (!adId && responses.length > 1) {
						responses = [...responses].sort((a, b) => {
							// eslint-disable-next-line eqeqeq
							if (a.cpm == b.cpm) {
								return 0;
							}
							return a.cpm < b.cpm ? 1 : -1;
						});
					}
					Utils.find(responses, (bidResp) => (
						(!adId || bidResp.adId === adId) && relevantDigital.Auction?.markAdserverWon?.(bidResp)
					));
				}
			}
		}
	}

	hasRenderedDivId(divId) {
		const elm = this.renderedDivs[divId];
		return elm && elm === this.domInterface.getElementById(divId);
	}

	resetPageState() {
		this.renderedDivs = {};
		this.lazyLoader?.reset();
	}

	isLatestAuction(requestAuction) {
		return this.pendingAuctions.length && this.pendingAuctions[this.pendingAuctions.length - 1] === requestAuction;
	}

	allAuctions() { // Won't return auctions being initialized
		return [...this.doneAuctions, ...this.pendingAuctions];
	}

	auctionById(auctionId) {
		return Utils.find(this.allAuctions(), (a) => a.auctionId === auctionId);
	}

	getAdUnitInstanceByBid(bid) {
		return this.auctionById(bid.auctionId)?.usedUnitDatas.find((u) => u.code === bid.adUnitCode);
	}

	getBidderHandler(bid) {
		return BidderHandler.of(bid);
	}

	videoStorageFn(orgFn) {
		// Insert ad id in Prebid's wrapper ad in order to identify impression later
		// in relevantDigital.Auction.registerImpressionByAdId()
		return function (bid, ...rest) {
			const res = orgFn.call(this, bid, ...rest);
			const id = bid?.creativeId || bid?.adId;
			if (id && typeof res?.value === 'string' && !bid.vastXml) {
				res.value = res.value.replace('<Ad>', `<Ad id="${id}--${Math.random().toString().slice(2)}">`);
			}
			return res;
		};
	}

	runNextAuction() {
		const waiting = Utils.find(this.pendingAuctions, (a) => a.state === WAITING);
		const running = Utils.find(this.pendingAuctions, (a) => (
			a.state === RUNNING || a.state === AD_REQUESTING_RUNNING
		));
		if (waiting && !running) {
			const hadRun = !!this.hasRunOnce;
			this.hasRunOnce = true;
			waiting.run({ isFirstCall: !hadRun });
		}
	}

	aliasBidder(orgName, finalName) {
		if (this.aliases[finalName]) {
			return;
		}
		this.aliases[finalName] = orgName;
		const { s2sConfig } = this.prebidConfig;
		if (s2sConfig) {
			const { aliases } = s2sConfig.extPrebid;
			if (Utils.values(aliases).indexOf(orgName) >= 0) { // This is an S2S bidder
				aliases[s2sBidderSuffix(finalName)] = orgName;
				s2sConfig.bidders.push(s2sBidderSuffix(finalName));
			}
		}
	}

	initializePrebidConfig(auction) {
		const { pbjs } = this;
		if (!this.prebidConfigInitialized && pbjs && pbjs.aliasBidder && pbjs.setConfig) {
			this.prebidConfigInitialized = true;
			this.setConfigCurrency(this.prebidConfig.currency?.adServerCurrency || 'USD');
			Utils.entries({ ...this.aliases, ...this.s2sAliases }).forEach(([finalName, orgName]) => {
				const { gvlid } = this.providedObjects.adapterManager?.bidderRegistry?.[orgName]?.getSpec?.() || {};
				auction.pbjsCall('aliasBidder', orgName, finalName, gvlid ? { gvlid } : undefined);
			});
			auction.pbjsCall('setConfig', this.prebidConfig);
			auction.pbjsCall('onEvent', 'beforeRequestBids', (bids) => this.onBeforeRequestBids(bids));
			Utils.mergeNoArr(this.pbjs, { bidderSettings: this.bidderSettings });
			pbjsInject(this);
		}
	}

	onBeforeRequestBids(adUnits) {
		const unitWithBids = Utils.find(adUnits || [], (u) => (u.bids || []).length);
		if (!unitWithBids) {
			return;
		}
		const auction = this.auctionById(unitWithBids.bids[0]?.auctionId);
		if (auction) {
			auction.onBeforeRequestBids(adUnits);
		}
	}

	onAuctionDone(requestAuction) {
		this.pendingAuctions = this.pendingAuctions.filter((a) => a !== requestAuction);
		this.doneAuctions.push(requestAuction);
		this.auctionDoneListeners.forEach((cb) => cb(requestAuction));
		this.runNextAuction();
	}

	getUnitInstanceForId(elmId) {
		const auctions = this.allAuctions().filter((a) => a.state >= AD_REQUESTING_RUNNING);
		for (let i = auctions.length - 1; i >= 0; i -= 1) {
			const instance = Utils.find(auctions[i].usedUnitDatas || [], ({ slot }) => (
				slot.getSlotElementId() === elmId
			));
			if (instance) {
				return instance;
			}
		}
		return null;
	}

	getLastDoneAuction() {
		return this.doneAuctions[this.doneAuctions.length - 1];
	}

	addAuctionDoneListener(cb) {
		this.auctionDoneListeners.push(cb);
	}

	addPrebidConfig(pbConfig) {
		Utils.merge(this.prebidConfig, pbConfig);
	}

	addAmazonConfig(amazonConfig) {
		Utils.merge(this.amazonConfig, amazonConfig);
	}

	getConfigs() {
		return Utils.values(this.configs);
	}

	/**
	 * Run callback function when no auctions being initialized
	 */
	waitForAuctionsInit(cb) {
		if (this.initAuctionCount) {
			this.initAuctionListeners.push(cb);
		} else {
			cb();
		}
	}

	addUserIdModules(userSync) {
		// If already set via addPrebidConfig, don't overwrite
		const existing = this.prebidConfig.userSync.userIds || [];
		this.prebidConfig.userSync.userIds = [
			...existing,
			...userSync.userIds.filter(({ name }) => (
				!Utils.find(existing, (obj) => obj.name === name)
			)),
		];
	}

	getTestRand(name, useLocalStorage) {
		const { rands } = this;
		rands[name] = rands[name] || Math.random();
		if (useLocalStorage) {
			const stored = this.storage.data[name];
			if (typeof stored !== 'number' || stored < 0 || stored >= 1) {
				this.storage.update({ [name]: rands[name] });
			} else {
				rands[name] = stored;
			}
		}
		return rands[name];
	}

	/**
	 * Simple A/B test configuration selection
	 * @param {*} configId parent config id
	 * @returns config within the range or parent
	 */
	selectConfiguration(configId, doneCb) {
		const pbConfig = this.configs[configId];
		if (!pbConfig) {
			throw Error(`Non-existing config id: '${configId}'`);
		}

		let childCfgs = Utils.values(this.configs).filter((cfg) => cfg.parentConfigId === configId);
		if (!childCfgs.length) {
			doneCb(pbConfig);
			return;
		}
		const doSelect = () => {
			const abTestRand = this.getTestRand('abTestRand', pbConfig.abTestLocalStorage);
			// Example: if we're having percentages like [4,7,2] => select these when [0..4, 4..11, 11..13]
			let passedBy = 0;
			const res = Utils.find(childCfgs, (cfg) => {
				passedBy += cfg.percentage / 100;
				return abTestRand <= passedBy;
			}) || pbConfig;
			doneCb(res);
		};
		if (Utils.find(childCfgs, (cfg) => cfg.country.length)) { // we need geo-information
			this.loadGeoWithTimeout(({ geoCountry }) => {
				childCfgs = childCfgs.filter(({ country }) => !country.length || country.indexOf(geoCountry) >= 0);
				doSelect();
			});
		} else {
			doSelect();
		}
	}

	loadGeoWithTimeout(doneCb, timeoutMs) {
		this.loadGeo((country) => {
			this.geoCountry = country;
			doneCb(this);
		}, timeoutMs || this.geoWaitMs);
	}

	applyConfigData(pbConfig) {
		const { data } = pbConfig;
		const { rlvTimeouts = {}, rlvCfgJs, rlvFloorEnforce } = data;
		for (const key in rlvTimeouts) {
			if (rlvTimeouts[key]) {
				this[key] = rlvTimeouts[key];
			}
		}

		if (data.rlvBidCache) {
			this.prebidConfig.useBidCache = true;
		}
		if (rlvFloorEnforce) {
			const { floors } = this.prebidConfig;
			floors.enforcement = floors.enforcement || {};
			Utils.assign(this.prebidConfig.floors, rlvFloorEnforce);
		}

		this.initPbjsTimeout();
		if (rlvCfgJs) {
			Utils.evalWithVars(rlvCfgJs, { pbConfig, data: pbConfig.data });
		}
	}

	loadPrebid(settings) {
		const now = new Date();
		this.selectConfiguration(settings.configId, (pbConfig) => {
			const loadInternal = () => this.loadPrebidInternal(settings, pbConfig);
			if (this.optimization?.shouldOptimize(pbConfig)) {
				const { rlvOptWait, rlvOptTimeoutMinutes } = pbConfig.data;
				// deduct possible geo-lookup-delay from time we should wait
				const timeout = Math.max(rlvOptWait - (new Date() - now), 0);
				this.optimization.loadOptimization(loadInternal, timeout, {
					waitIfOlderMs: rlvOptTimeoutMinutes * 60 * 1000,
				});
			} else {
				loadInternal();
			}
		});
	}

	loadPrebidInternal(settings, pbConfig) {
		const { videoSlots } = settings;
		// Find appropriate configuration
		if (!this.hasLoadedOnce) {
			this.hasLoadedOnce = true;
			this.runOnFirstLoadPrebid();
			this.applyConfigData(pbConfig);
		}
		if (videoSlots) {
			this.defineVideoSlots(videoSlots);
		}
		if (pbConfig.userSync) {
			this.addUserIdModules(pbConfig.userSync);
		}
		log(`Starting auction with config '${pbConfig.name}'`);
		const auctionCb = () => {
			const requestAuction = new RequestAuction(this, pbConfig, settings);
			this.initAuctionCount += 1;
			requestAuction.init({
				doneCb: () => {
					this.pendingAuctions.push(requestAuction);
					this.initAuctionCount -= 1;
					if (this.initAuctionCount === 0) {
						this.initAuctionListeners.forEach((cb) => cb());
						this.initAuctionListeners = [];
					}
					this.runNextAuction();
				},
			});
		};
		// loadGeo only if geo filtering is enabled and countries are specified
		if (pbConfig.data.rlvBySsp && Utils.values(pbConfig.data.rlvBySsp).some((ssp) => ssp.filterType && ssp.countries)) {
			this.loadGeoWithTimeout(auctionCb);
		} else {
			auctionCb();
		}
	}

	runOnFirstLoadPrebid() {
		// As turns out, the hack below is the only way to avoid an unnecessary error-message from the floor-price
		// module when there are no floors specified in auction.
		this.pbjs.que.push(() => {
			const obj = this.providedObjects.floorsSchemaValidation;
			const orgFn = obj?.[1];
			if (typeof orgFn === 'function') {
				obj[1] = function (data) {
					// eslint-disable-next-line prefer-rest-params
					return data?.schema ? orgFn.apply(this, arguments) : false;
				};
			}
		});
	}

	/**
	 * Keep track of video ad units
	 * After collecting no-refresh (video) ad-units they can't be "re-collected"
	 */
	collectVideoAdUnits(ids, cb) {
		let done;
		const collectAdUnitsFromDoneUnits = () => {
			// Safety check, might be trying to run this function again
			if (done) {
				return true; // already done
			}
			// Will be filled with pbAdUnits if not already collected
			const collectStatus = ids.map((id) => ({ id }));
			this.doneAuctions.forEach(({ usedUnitDatas }) => {
				usedUnitDatas.forEach((unitData) => {
					// Throw away if not a noAutoRefreshAdUnit (video) or if already collected,
					const { adserver, slot, __collected } = unitData;
					if (!(slot instanceof VideoSlot) || __collected) {
						return;
					}
					for (const status of collectStatus) {
						if (!status.unitData && adserver.doesSlotMatch(slot, status.id)) {
							status.unitData = unitData;
							break; // found it
						}
					}
				});
			});
			// We got all ad units
			const isDone = collectStatus.every(({ unitData }) => unitData);
			if (isDone) {
				const res = [];
				collectStatus.forEach(({ unitData }) => {
					res.push(unitData);
					unitData.__collected = true;
				});
				done = true;
				cb(res);
			}

			return isDone;
		};
		this.waitForAuctionsInit(() => {
			// If already done
			if (collectAdUnitsFromDoneUnits()) {
				return;
			}
			this.auctionDoneListeners.push(collectAdUnitsFromDoneUnits);
		});
	}

	generateVideoUrls(unitDatas, doneCb, { onBuildVideoUrl } = {}) {
		const datas = [];
		const callDone = () => doneCb(datas.map((d) => d.url), datas);
		let remainingCount = unitDatas.length;
		unitDatas.forEach((unitData) => {
			const { pbAdUnit, slot, adserver, adUnit } = unitData;
			// set report config key
			const globalTargeting = adserver.getGlobalTargeting();
			const floorTarg = adserver.getFloorTargeting?.(adUnit.adsFloor);
			if (globalTargeting || floorTarg) {
				slot.custParams = {
					...slot.custParams,
					...globalTargeting,
					...floorTarg,
				};
			}
			if (pbAdUnit.mediaTypes.video.context === 'instream') {
				// Use sizes + playerSize for 'sz'
				const sizes = pbAdUnit.sizes.map(([w, h]) => `${w}x${h}`);
				const { playerSize } = pbAdUnit.mediaTypes.video;
				if (playerSize) {
					const [playerSizeWidth, playerSizeHeight] = playerSize;
					const pSizeStr = `${playerSizeWidth}x${playerSizeHeight}`;
					if (sizes.indexOf(pSizeStr) < 0) {
						sizes.push(pSizeStr);
					}
				}
				const sz = sizes.join('|');
				const { protocol, hostname, pathname } = Utils.getLocation() || {};
				const params = {
					adUnit: pbAdUnit,
					params: {
						iu: slot.getAdUnitPath(),
						output: 'vast',
						...(protocol && {
							description_url: encodeURIComponent(`${protocol}//${hostname}${pathname}`),
						}),
						wta: 1,
						sz,
						...(slot.custParams && { cust_params: slot.custParams }),
					},
				};
				onBuildVideoUrl?.(params);
				const url = this.pbjs.adServers?.dfp?.buildVideoUrl(params);
				datas.push({ url, unitData, gamParams: params });
				remainingCount -= 1;
			}
		});

		if (remainingCount === 0) {
			callDone();
		}
	}

	loadVideoUrls(ids, cb, settings) {
		this.collectVideoAdUnits(ids, (unitData) => {
			this.generateVideoUrls(unitData, cb, settings);
		});
	}

	defineVideoSlots(slots) {
		return slots.map((slotData) => {
			const {
				path, id, custParams, ...rest
			} = slotData;
			return VideoSlot.getOrCreateSlot(
				id || `${path}${Math.random().toString()}`,
				path,
				{ custParams, ...rest },
				true,
			);
		});
	}

	provideObject(obj) {
		Utils.assign(this.providedObjects, obj);
	}

	static init({ FIELDS, SITE, log: lg }) {
		const { relevantDigital } = window;
		log = lg;
		EXPORT_FNS.forEach((fnName) => {
			relevantDigital[fnName] = (...args) => PrebidRequester.instance[fnName](...args);
		});
		relevantDigital.getInstance = () => PrebidRequester.instance;
		PrebidRequester.instance = new PrebidRequester(FIELDS, SITE);
		AdserverBase.baseStaticInit(PrebidRequester.instance, AdserverTypes);
		Utils.values(AdserverTypes).forEach((type) => type.staticInit && type.staticInit(PrebidRequester.instance));
	}
}

module.exports = PrebidRequester;
