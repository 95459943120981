window.relevantDigital = window.relevantDigital || {};
const rd = window.relevantDigital;
rd.cmd = rd.cmd || [];
rd.exports = {};
rd.import = (name) => {
	let res = rd.exports[name];
	if (!res) {
		res = {};
		rd.exports[name] = res;
	}
	return res;
};
rd.export = (obj) => {
	for (const k in obj) {
		if (rd.exports[k]) {
			for (const key in obj[k]) {
				rd.exports[k][key] = obj[k][key];
			}
		} else {
			rd.exports[k] = obj[k];
		}
	}
};
