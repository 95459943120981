const GamAdserver = require('GamAdserver');
const InMemoryAdserver = require('InMemoryAdserver');
const SmartAdserver = require('SmartAdserver');
const AdformAdserver = require('AdformAdserver');
const DemoAdserver = require('DemoAdserver');
const XandrAdserver = require('XandrAdserver');
const AdnuntiusAdserver = require('AdnuntiusAdserver');

/**
 * REMEMBER!! - to also update ./adservers/adserverList.js
 */
const AdserverTypes = {
	GamAdserver,
	DemoAdserver,
	InMemoryAdserver,
	SmartAdserver,
	AdformAdserver,
	XandrAdserver,
	AdnuntiusAdserver,
};

for (const key in AdserverTypes) {
	if (!AdserverTypes[key]) {
		delete AdserverTypes[key];
	}
}

module.exports = AdserverTypes;
