const inject = (orgFnObj, orgFnName, newFn) => {
	const orgFn = orgFnObj[orgFnName];
	orgFnObj[orgFnName] = function (...args) {
		const meta = {
			that: this,
			orgCall: (...a) => orgFn.call(this, ...a),
		};
		return newFn(meta, ...args);
	};
};

const pbjsInject = (pbRequester) => {
	const { providedObjects } = pbRequester;
	const { ORTB2, PBS_CONVERTER } = providedObjects;
	const handlePbsResponse = (response) => {
		(response?.seatbid || []).forEach((seatBid) => {
			(seatBid.bid || []).forEach((bid) => {
				pbRequester.addLowLevelRlvBidResponseInfo(bid);
			});
		});
	};
	if (ORTB2) {
		inject(ORTB2.prototype, 'interpretResponse', ({ orgCall }, response, ...rest) => {
			handlePbsResponse(response);
			return orgCall(response, ...rest);
		});
	} else if (PBS_CONVERTER) { // PBJS 7.23+
		inject(PBS_CONVERTER, 'fromORTB', ({ orgCall }, param, ...rest) => {
			handlePbsResponse(param?.response);
			return orgCall(param, ...rest);
		});
	}
};

module.exports = pbjsInject;
