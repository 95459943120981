const Utils = require('./utils');

function getAppnexusOutstreamRendererPlaybackSettings(playbackMethod) {
	switch (playbackMethod) {
		// Initiates on Page Load with Sound On
		case 1:
			return {
				initialPlayback: 'auto',
				initialAudio: 'on',
			};
		// Initiates on Page Load with Sound Off by Default
		case 2:
			return {
				initialPlayback: 'auto',
				initialAudio: 'off',
			};
		// Initiates on Click with Sound On
		case 3:
			return {
				initialPlayback: 'click',
				initialAudio: 'on',
			};
		// Initiates on Mouse-Over with Sound On
		case 4:
			return {
				initialPlayback: 'mouseover',
				initialAudio: 'on',
			};
		// Initiates on Entering Viewport with Sound On
		case 5:
			return {
				initialPlayback: 'auto',
				initialAudio: 'on',
				nonViewableBehavior: 'pause',
			};
		// Initiates on Entering Viewport with Sound Off by Default
		case 6:
			return {
				initialPlayback: 'auto',
				initialAudio: 'off',
				nonViewableBehavior: 'pause',
			};
		default:
			return {};
	}
}

function generateAppnexusOutstreamRendererSettings({
	playbackmethod,
	adText,
	skip,
	skipText,
	skipButtonText,
	allowOverride,
	expandTime,
	endBehavior,
	width,
	height,
	enableMaxHeight,
	xandrRenderOptions,
}) {
	const playbackSettings = getAppnexusOutstreamRendererPlaybackSettings(playbackmethod[0]);
	const res = {
		skippable: {
			enabled: Boolean(skip),
			skipText,
			skipButtonText,
			allowOverride,
		},
		disableCollapse: {
			enabled: !endBehavior.collapse,
			replay: endBehavior.replay,
			hideControlBar: endBehavior.hideControlBar,
		},
		maxHeight: {
			enabled: enableMaxHeight,
			height,
		},
		expandTime,
		adText,
		width,
		height,
		...playbackSettings,
	};
	Utils.mergeNoArr(res, xandrRenderOptions);
	return res;
}

module.exports = {
	generateAppnexusOutstreamRendererSettings,
};
