const AmazonAuction = require('./amazonAuction');

const Utils = window.relevantDigital.import('Utils');
const BidderHandler = window.relevantDigital.import('BidderHandler');

const init = (instance) => {
	const { pbRequester, adserverIntegration, pubId } = instance;
	if (!window.apstag) { // Create "stub" + load
		const enqueue = (op, args) => window.apstag._Q.push([op, args]);
		window.apstag = {
			init: (...args) => enqueue('i', args),
			fetchBids: (...args) => enqueue('f', args),
			setDisplayBids: () => {},
			targetingKeys: () => [],
			_Q: [],
		};
		Utils.loadScript('//c.amazon-adsystem.com/aax2/apstag.js');
	}
	window.apstag.init({
		pubID: pubId,
		adServer: adserverIntegration.adServerName,
		...pbRequester.amazonConfig,
	});
};

const AmazonInterface = {
	canUseWithAdserver(adserver) {
		const adsAmazonInfo = adserver.getAmazonIntegrationInfo();
		if (!adsAmazonInfo) {
			return false;
		}
		if (!AmazonInterface.instance) {
			return true;
		}
		// It's not possible to use Amazon with different adservers (e.g. GAM vs Xandr) simultantiously
		const { adServerName: currentAdsName } = AmazonInterface.instance.adserverIntegration;
		return adsAmazonInfo.adServerName === currentAdsName;
	},

	getFirstValidAmazonBid({ bids, adserver }) {
		if (!AmazonInterface.canUseWithAdserver(adserver)) {
			return null;
		}
		// There can only be a single Amazon "bid" per ad unit
		return Utils.find(bids, (bid) => BidderHandler.of(bid).type === 'AmazonSsp' && bid.params.pubId);
	},

	createAuction({ auction, adserver }) {
		const { pbRequester } = auction;
		if (!AmazonInterface.instance) {
			// Let's use the first seen publisher-id. If one manages to create a setup where different
			// Amazon accounts will be used for the same page-view it will not work, as we're just running
			// apstag.init() once. We might think (later) about preventing using 2 Amazon-accounts in the same prebid
			// configuration + add some warning about not loading 2 prebid configs in the same page-view if they
			// contain different accounts in between.
			const { pubId } = auction.amazonAdUnitInstances[0].adUnit.amazonBid.params;
			AmazonInterface.instance = {
				pbRequester,
				adserverIntegration: adserver.getAmazonIntegrationInfo(),
				pubId,
			};
			init(AmazonInterface.instance);
			AmazonAuction.staticInit(AmazonInterface);
		}
		return new AmazonAuction({ auction, adserver });
	},

	initAmazonAuctionFor(auction) {
		const { usedUnitDatas, amazonAdUnitInstances, adservers } = auction;
		const amzDatas = usedUnitDatas.filter((u) => u.adUnit.amazonBid);
		// We only support *one* adserver for Amazon, select the most common one,
		// OR in the case we're being called again, the same adserver as last time
		// If we're doing multiple auctions we also know that amzDatas corresponds to the same
		// adserver-type as was used before as it is checked by AmazonInterface.canUseWithAdserver()
		let amzAdserver = adservers[0]; // default
		if (amzDatas.length) {
			if (amazonAdUnitInstances?.length) {
				amzAdserver = amazonAdUnitInstances[0].adserver; // same as last time
			} else if (adservers.length > 1) {
				const byAdsId = {};
				let mostUsual;
				amzDatas.forEach((unitData) => {
					const { adserver } = unitData;
					let info = byAdsId[adserver.id];
					if (!info) {
						info = { adserver, count: 0 };
						byAdsId[adserver.id] = info;
					}
					info.count += 1;
					if (!mostUsual || info.count > mostUsual.count) {
						mostUsual = info;
					}
				});
				amzAdserver = mostUsual.adserver;
			}
		}
		auction.amazonAdUnitInstances = amzDatas.filter(({ adserver }) => adserver === amzAdserver);
		if (auction.amazonAdUnitInstances.length && !auction.amazonAuction) {
			auction.amazonAuction = AmazonInterface.createAuction({ auction, adserver: amzAdserver });
		}
	},
};

window.relevantDigital.export({ AmazonInterface });
