const Utils = window.relevantDigital.import('Utils');

const isWithinLoadingArea = ({ div, pixels }) => {
	const {
		top, bottom, left, right,
	} = div.getBoundingClientRect();
	if (!top && !bottom && !left && !right) {
		return false;
	}
	return top < window.innerHeight + pixels && bottom > -pixels;
};

class LazyLoader {
	constructor(settings) {
		Utils.assign(this, {
			...settings,
			auctionDatas: [],
		});
	}

	reset() {
		this.auctionDatas.forEach((auctionData) => (
			auctionData.lazyInstances.forEach(({ observer }) => observer && observer.disconnect())
		));
		this.auctionDatas = [];
	}

	/**
	 * Creates new auction(s) after placement(s) has become visible
	 * As the original loadPrebid(..) settings *might* be different we're NOT trying to
	 * merge placements from different auctions into one. */
	lazyLoad() {
		this.loadScheduled = false;
		this.auctionDatas.forEach((auctionData) => {
			const toLoad = [];
			const toNotLoad = [];
			auctionData.lazyInstances.forEach((lazyUnit) => {
				if (!lazyUnit.observer) { // This marks that we should load this placement
					toLoad.push(lazyUnit);
				} else {
					toNotLoad.push(lazyUnit);
				}
			});
			auctionData.lazyInstances = toNotLoad;
			if (toLoad.length) {
				const { auction } = auctionData;
				const settings = {
					...auction.settings, // copy previous auction-settings
					isLazyLoadAuction: true,
					noSlotReload: true,
					allowedDivIds: toLoad.map(({ div }) => div.id),
				};
				[...auction.adservers].reverse().forEach((ads) => {
					if (!ads.isInstreamOnly()) {
						ads.finalizeLazyLoadSettings(settings);
					}
				});
				this.pbRequester.loadPrebid(settings); // Trigger next auction
			}
		});
		this.auctionDatas = this.auctionDatas.filter((data) => data.lazyInstances.length);
	}

	/**
	 * This check is needed in order to support lazy loading of manually created slots,
	 * e.g. when using googletag.defineSlot() instead of <div data-ad-unit-id="??"> */
	isLazyLoadingSlot(auction, slot) {
		const auctionData = Utils.find(this.auctionDatas, (data) => data.auction === auction);
		return auctionData && auctionData.seenSlots.has(slot);
	}

	/**
	 * Returns true if 'adUnit' should be lazy loaded later
	 * Only one of 'div' and 'slot' must be provided. 'div' will be provided in the "manageAdserver: true" scenario
	 * where we haven't created any slot yet (and will not do in case the function returns true) */
	scheduleLazyLoad({
		auction, adUnit, div: d, slot,
	}) {
		const settings = adUnit.data.rlvLazy;
		if (!settings?.enabled || auction.isLazyLoadAuction || !window.IntersectionObserver) {
			return false;
		}
		const div = d || (slot && document.getElementById(slot.getSlotElementId()));
		if (!div || !div.id || document.getElementById(div.id) !== div) {
			return false;
		}
		const lazyInstance = { ...settings, div };
		if ('mobileScaling' in settings && window.relevantDigital.platformData?.platform?.type === 'mobile') {
			lazyInstance.pixels *= settings.mobileScaling;
		}
		if (isWithinLoadingArea(lazyInstance)) {
			return false;
		}
		lazyInstance.observer = new IntersectionObserver(([entry], observer) => {
			if (!entry?.isIntersecting) {
				return;
			}
			observer.disconnect();
			lazyInstance.observer = null;
			if (!this.loadScheduled) {
				this.loadScheduled = true;
				// Use timeout in order to pick up 2+ placements that becomes visible
				// simultaneously so that they can be requested in the same auction.
				setTimeout(() => this.lazyLoad());
			}
		}, { rootMargin: `${lazyInstance.pixels}px` });
		lazyInstance.observer.observe(div);
		let auctionData = Utils.find(this.auctionDatas, (data) => data.auction === auction);
		if (!auctionData) {
			auctionData = { auction, lazyInstances: [], seenSlots: new WeakMap() };
			this.auctionDatas.push(auctionData);
		}
		auctionData.lazyInstances.push(lazyInstance);
		if (slot) {
			auctionData.seenSlots.set(slot, true);
		}
		return true;
	}
}

window.relevantDigital.export({ LazyLoader });
