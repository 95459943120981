const Utils = require('./utils');
const AdUnitInstance = require('./adUnitInstance');
const { GAM_DUPLICATE_PATH_SEPARATOR, DEFAULT_PLACEMENT_PATH } = require('./sharedConstants');
const DivSlot = require('./divSlot');
const VideoSlot = require('./videoSlot');

const { attribToBool } = Utils;

const getSlotInfosFromPathMap = (pathMap, path) => {
	if (pathMap[path]) {
		return pathMap[path];
	}
	const idx = path.indexOf('*');
	if (idx < 0) {
		return [];
	}
	const start = path.substr(0, idx);
	const end = path.substr(idx + 1);
	const slots = [];
	for (const cand in pathMap) {
		if (cand.substr(0, idx) === start && (!end.length || cand.substr(-end.length) === end)) {
			slots.push(...pathMap[cand]);
		}
	}
	return slots;
};

class AdserverBase {
	constructor(auction, settings) {
		Utils.assign(this, {
			unknownSlotsToLoad: [],
			bidInfo: {},
			auction,
			...settings,
		});
		this.waitHba = (fn) => this.auction.events.hbaAuctionCreated.wait(fn);
	}

	runInit(...args) {
		const cls = this.constructor;
		if (!cls.firstTimeInitDone) {
			this.doFirstTimeInit?.();
			cls.firstTimeInitDone = true;
			this.initEvents();
		}
		this.init(...args);
	}

	initEvents() {
		const { Auction, getInstance } = window.relevantDigital;
		const handler = (cb) => (ev) => {
			const { adserverBid, auction, adserver } = getInstance().getUnitInstanceForId(ev.slot.getSlotElementId()) || {};
			if (adserverBid) {
				auction.events.hbaAuctionCreated.wait(() => (
					Auction.update(adserverBid, (bid) => cb(bid, adserver.prepareHbaBidEvent?.(bid, ev) || ev))
				));
			}
			return true;
		};
		const hasAd = (ev) => ev.lineItemId || ev.isEmpty === false;
		const handlers = {
			slotRequested: (bid) => {
				bid.__reqStart = new Date();
			},
			slotResponseReceived: (bid, ev) => {
				const { lineItemId, size } = ev;
				Auction[hasAd(ev) ? 'bidResponse' : 'noBid'](bid);
				if (bid.__reqStart) {
					bid.responseMs = new Date() - bid.__reqStart;
				}
				bid.lineItemId = lineItemId;
				['width', 'height'].forEach((fld, idx) => {
					bid[fld] = size?.[idx] || 0;
				});
			},
			slotRenderEnded: (bid, ev) => {
				if (hasAd(ev)) {
					Auction.bidWon(bid);
				}
			},
		};
		Utils.entries(handlers).forEach(([k, v]) => this.waitEvent(k, handler(v)));
	}

	waitEvent(type, cb) {
		const eventIfs = this.getEventIfs?.();
		const listener = (ev) => {
			if (ev.rlvStopPropagation) {
				return;
			}
			let finalEv = {
				...ev,
				stopPropagation: () => {
					ev.rlvStopPropagation = true;
				},
			};
			finalEv = eventIfs?.transform?.(finalEv) || finalEv;
			const doContinue = cb(finalEv);
			if (!doContinue) {
				eventIfs?.remove(type, listener);
			}
		};
		return eventIfs?.add(type, listener);
	}

	static baseStaticInit(pbRequester, AdserverTypes) {
		// "Expose" 'relevantDigital.destroySlots()'
		const destroySlots = () => {
			pbRequester.resetPageState();
			AdserverBase.usedIds = {};
			DivSlot.idToSlot = {};
			Utils.values(AdserverTypes).forEach((type) => {
				if (type.destroySlots) {
					type.destroySlots();
				}
			});
		};
		Utils.assign(window.relevantDigital, {
			destroySlots,
			resetSlotReloadState: destroySlots, // Keep old name for backward-compatibility with MTV's code
		});
	}

	get adUnits() {
		const { adservers, adUnits } = this.auction;
		return adservers.length === 1 ? adUnits : adUnits.filter(({ adserver }) => adserver === this);
	}

	isInstreamOnly() {
		return this.auction.bannerAdsIds.indexOf(this.id) < 0 // no banner placements in prebid configuration for us
			|| this.auction.allowedPlacementType === 'instream'; // or.. we're not loading any of them anyway
	}

	init(__, cb) {
		cb();
	}

	getType() {
		return 'unknown';
	}

	getAdserverProps() {
		return {
			delayedSendAdserver: false,
		};
	}

	shouldConvertPathToLowercase() {
		return true;
	}

	fixPath(p) {
		const res = (p || '').toString().trim();
		return this.shouldConvertPathToLowercase() ? res.toLowerCase() : res;
	}

	normalizeAdUnitPath(path) {
		return path;
	}

	getCodeStart(path) {
		return path;
	}

	getAmazonIntegrationInfo() {
		return null;
	}

	finalizeReloadSettings(settings) {
	}

	finalizeLazyLoadSettings(settings) {
	}

	get reportPbCfg() {
		return this.auction?.pbRequester?.reportPbCfg;
	}

	getSlotResponseInfo() {
		return null;
	}

	getGlobalTargeting() {
		if (this.reportPbCfg) {
			const { configId, name } = this.auction.pbConfig;
			return {
				[this.adsPbReportConfigIdKey]: configId,
				[this.adsPbReportConfigNameKey]: name,
			};
		}
		return null;
	}

	adUnitPathsFromUnitInternal(unit) {
		throw Error('Not implemented');
	}

	updateAdUnitPath(dstAdUnit, adUnitPath) {
		return false;
	}

	adUnitPathsFromUnit(unit) {
		return this.adUnitPathsFromUnitInternal(unit).map((p) => this.fixPath(p)).filter((p) => p);
	}

	getSlots() {
		throw Error('Not implemented');
	}

	createSlot({ path, sizes, divId }) {
		throw Error('Not implemented');
	}

	createSlotFromAdUnit(params) {
		const { adUnit } = params;
		return this.createSlot({
			sizes: adUnit.getPrebidSizes(),
			placementType: adUnit.getPlacementType(),
			...params,
		});
	}

	createDivId(path) {
		return `ad-id-${path}-${Math.random().toString().slice(2)}`;
	}

	oneTimePageSetup() {}

	adUnitFromSlotPath(path, isPageSupplied) {
		const normalized = this.normalizeAdUnitPath(path, isPageSupplied);
		const matches = [];
		this.adUnits.forEach((unit) => {
			const paths = this.adUnitPathsFromUnit(unit);
			paths.forEach((cand) => {
				let match = cand === normalized;
				if (!match) {
					const idx = cand.indexOf('*');
					match = idx >= 0
						&& normalized.substr(0, idx) === cand.substr(0, idx)
						&& (idx === cand.length - 1
							|| normalized.substr(-(cand.length - idx - 1)) === cand.substr(idx + 1)
						);
				}
				if (match) {
					matches.push({ path: cand, unit });
				}
			});
		});
		return Utils.byPathLenSort(matches)[0]?.unit;
	}

	setup(settings, statePerDiv) {
		const { auction } = settings;
		const {
			allowedDivIds,
			collapseEmptyDivs,
			collapseBeforeAdFetch,
			divToAdUnit,
			divAttribute,
			pbRequester,
		} = auction;
		const cls = AdserverBase.constructor;
		if (!cls.oneTimeSetupDone) {
			this.oneTimePageSetup(settings);
			cls.oneTimeSetupDone = true;
		}
		const { domInterface } = pbRequester;
		const divs = Array.prototype.slice.call(domInterface.querySelectorAll(`[${divAttribute}]`));

		divs.forEach((div) => {
			if (statePerDiv.get(div) === true) {
				return; // already processed by other adserver
			}
			const path = this.fixPath(div.getAttribute(divAttribute));
			if (allowedDivIds) {
				if (allowedDivIds.indexOf(div.id) < 0) {
					return;
				}
			}
			const setError = (err) => statePerDiv.set(div, { err, div, auction, path });
			if (!div.id) {
				const candidateId = this.createDivId(path);
				if (!candidateId) {
					setError(`Ad unit path not accepted: ${path}`);
					return;
				}
				div.id = this.createDivId(path);
			}
			const divId = div.id;
			const getAdUnit = (p) => (!divToAdUnit ? this.adUnitFromSlotPath(p, true) : divToAdUnit({
				div, path, adUnits: this.adUnits, auction, defaultFn: () => this.adUnitFromSlotPath(p, true),
			}));
			let adUnit = getAdUnit(path);
			if (!adUnit) {
				adUnit = getAdUnit(DEFAULT_PLACEMENT_PATH);
				if (adUnit) {
					adUnit = auction.addUnitFromTemplate(adUnit, path, this);
				}
			}
			if (!adUnit) {
				setError(`No ad unit for: ${path}`);
				return;
			}
			statePerDiv.set(div, true);
			// Is instream or adpod video unit
			const isVideoUnit = attribToBool(div, 'data-video-unit');
			let slot;
			// Check if "fake" video slot
			if (isVideoUnit) {
				[slot] = auction.pbRequester.defineVideoSlots([{ path, id: divId }]);
			} else if (!Utils.find(this.getSlots(), (s) => s.getSlotElementId() === divId)) {
				if (auction.pbRequester.lazyLoader?.scheduleLazyLoad({ auction, adUnit, div })) {
					return; // scheduled for lazy load instead
				}
				slot = this.createSlotFromAdUnit({ path, divId, adUnit });
			}

			if (slot) { // else => already defined
				if (attribToBool(div, 'data-collapse-empty-divs', collapseEmptyDivs)) {
					const before = attribToBool(div, 'data-collapse-before-ad-fetch', collapseBeforeAdFetch);
					slot.setCollapseEmptyDiv(true, before);
				}
				if (attribToBool(div, 'data-no-refresh')) {
					slot.noRefresh = true;
				}
				slot.creatorPbAuction = auction;
			}
		});
	}

	normalizePathFromSlot(slot) {
		return this.fixPath(this.normalizeAdUnitPath(this.rlvConvertedAdUnitPath(slot)));
	}

	getPathMapping(ignoreSlots) {
		const {
			allowedDivIds,
			noSlotReload,
			allowedPlacementType,
		} = this.auction;
		const pathMap = {};
		const slotToInfo = new Map();
		let allSlots;
		if (allowedPlacementType === 'instream') {
			allSlots = VideoSlot.list();
		} else if (allowedPlacementType === 'banner') {
			allSlots = this.getSlots();
		} else {
			allSlots = [...VideoSlot.list(), ...this.getSlots()];
		}
		allSlots.forEach((slot) => {
			const divId = slot.getSlotElementId();
			if ((allowedDivIds && allowedDivIds.indexOf(divId) < 0)
				|| (noSlotReload && AdserverBase.usedIds[divId])
				|| ignoreSlots.has(slot)
			) {
				return;
			}
			const path = this.normalizePathFromSlot(slot);
			pathMap[path] = pathMap[path] || [];
			const info = { slot };
			slotToInfo.set(slot, info);
			pathMap[path].push(info);
		});
		return { pathMap, slotToInfo, allSlots };
	}

	getSlotInfoArr({ pathMap, slotToInfo }) {
		const res = [];
		const add = (unit, slotInfo, idx, path) => res.push({ unit, slotInfo, idx, path });
		this.adUnits.forEach((unit) => {
			unit.fixedSlots.forEach((slot) => {
				const info = slotToInfo.get(slot) || { slot };
				add(unit, info, 0, this.normalizePathFromSlot(slot));
			});
		});
		this.adUnits.forEach((unit) => {
			const paths = this.adUnitPathsFromUnit(unit);
			for (const path of paths) {
				const slotInfos = getSlotInfosFromPathMap(pathMap, path);
				slotInfos.forEach((slotInfo, idx) => {
					add(unit, slotInfo, idx, path);
				});
			}
		});
		return Utils.byPathLenSort(res);
	}

	getAdUnitInstances(requestAuction, ignoreSlots) {
		const {
			onSlotAndUnit,
			createAdUnitCode,
			hasSharedAdUnits,
			pbRequester,
			loadUnknownSlots,
			usedCodes,
			allowCodeReuse,
		} = requestAuction;
		const result = [];
		const pathMapping = this.getPathMapping(ignoreSlots);
		const infoArr = this.getSlotInfoArr(pathMapping);
		infoArr.forEach(({ unit, slotInfo, idx, path }) => {
			const { slot, done } = slotInfo;
			slotInfo.matched = true; // Slot has been matched to an existing unit
			if (done || pbRequester.lazyLoader?.scheduleLazyLoad({
				auction: requestAuction,
				adUnit: unit,
				slot,
			})) {
				return; // might happen if hasSharedAdUnits
			}
			let onSlotResult;
			if (onSlotAndUnit) {
				onSlotResult = onSlotAndUnit({
					slot,
					unit,
					requestAuction,
					samePathIdx: idx,
				});
				if (onSlotResult === false) {
					return;
				}
			}
			AdserverBase.usedIds[slot.getSlotElementId()] = true;
			let codeStart = hasSharedAdUnits ? unit.placementId : this.getCodeStart(path);
			codeStart = (createAdUnitCode && createAdUnitCode({ code: codeStart, unit, slot })) || codeStart;
			AdserverBase.usedCodes[codeStart] = (AdserverBase.usedCodes[codeStart] || 0) + 1;
			usedCodes[codeStart] = (usedCodes[codeStart] || 0) + 1;
			const codeReuse = allowCodeReuse || pbRequester.prebidConfig.useBidCache;
			const num = codeReuse ? usedCodes[codeStart] : AdserverBase.usedCodes[codeStart];
			const code = `${codeStart}${num > 1 ? `${GAM_DUPLICATE_PATH_SEPARATOR}${num}` : ''}`;

			AdserverBase.codeToId[code] = slot.getSlotElementId();

			result.push(new AdUnitInstance({
				adUnit: unit,
				slot,
				code,
				auction: requestAuction,
			}));
			if (!onSlotResult?.reUseSlot) {
				slotInfo.done = true;
			}
		});
		const unknownSlotsToLoad = [];
		if (loadUnknownSlots) {
			Utils.values(pathMapping.pathMap).forEach((arr) => arr.forEach(({ slot, matched }) => {
				if (!matched && !(slot instanceof VideoSlot)) {
					unknownSlotsToLoad.push(slot);
					AdserverBase.usedIds[slot.getSlotElementId()] = true;
				}
			}));
		}
		return {
			unitDatas: result,
			unknownSlotsToLoad,
		};
	}

	getAdDivId(pbAdUnit) {
		return pbAdUnit.__slot.getSlotElementId();
	}

	prepareIfrDoc(elm, { width, height }, cb) {
		const ifr = elm.getElementsByTagName('iframe')[0];
		if (!ifr) {
			cb();
		}
		if (ifr.contentDocument) {
			cb(ifr.contentDocument);
		}
		ifr.parentNode.style.width = `${width}px`;
		ifr.parentNode.style.height = `${height}px`;
		ifr.addEventListener('load', () => cb(ifr.contentDocument));
		ifr.src = 'about:blank';
	}

	doesSlotMatch(slot, id) {
		const divId = slot.getSlotElementId();
		const path = this.rlvConvertedAdUnitPath(slot).toLowerCase();
		return id === divId || Utils.cleanMcmPart(id.toLowerCase()) === path;
	}

	rlvConvertedAdUnitPath(slot) {
		return slot.getAdUnitPath();
	}
}

Utils.assign(AdserverBase, {
	usedIds: {},
	usedCodes: {},
	codeToId: {},
});

module.exports = AdserverBase;
