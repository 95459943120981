const Utils = window.relevantDigital.import('Utils');

const SPECIAL_BID_PARAMS = ['pubId', 'slotName'];

let AmazonInterface;

const getSlotName = ({ adUnit, slot }) => adUnit.amazonBid.params.slotName || slot.getAdUnitPath();

let amznRenderHook = null;
const amznRenderCallbacks = { };
// Hook the call to `apstag.renderImp`, which is called from the Amazon creative script, in order to detect an impression
function waitAmzniidRender(waitAmzniid, cb) {
	if (window.apstag.renderImp === undefined) {
		return;
	}

	if (window.apstag.renderImp !== amznRenderHook) {
		amznRenderHook = Utils.hookBefore(window.apstag.renderImp, (document, amzniid) => {
			amznRenderCallbacks[amzniid]?.(amzniid);
			delete amznRenderCallbacks[amzniid];
		});
		window.apstag.renderImp = amznRenderHook;
	}

	amznRenderCallbacks[waitAmzniid] = cb;
}

class AmazonAuction {
	constructor({ auction, adserver }) {
		Utils.assign(this, { auction, adserver });
		this.hbaBids = auction.amazonAdUnitInstances.map(({ code, slot, adUnit }) => ({
			bidder: adUnit.amazonBid.bidder,
			__rlvId: adUnit.amazonBid.__rlvId,
			params: {
				id: getSlotName({ adUnit, slot }),
			},
			adUnitCode: code,
			bidId: Utils.generateUUID(),
			auctionId: auction.auctionId,
		}));
	}

	static staticInit(amazonInterfaceCls) {
		AmazonInterface = amazonInterfaceCls;
	}

	slotStillBelongsToAuction(elmId) {
		const { auction } = this;
		const { pbRequester } = auction;
		if (pbRequester.getLastDoneAuction() === auction) {
			return true; // No need to check if slot has been reloaded by a later auction (as there is none)
		}
		const lastInstance = pbRequester.getUnitInstanceForId(elmId);
		if (!lastInstance) {
			return false; // Extremely unlikely edge-case that involves first using slot without HBM
		}
		return lastInstance.adUnit.auction === auction;
	}

	onBidsBack({ bids }) {
		const { amazonAdUnitInstances } = this.auction;
		const { adserver } = this;
		const { id: adserverId, amazonInfo } = adserver;
		const { orderIds = [] } = amazonInfo || {};
		const byId = Utils.keyBy(bids, 'slotID');
		const waitHba = (fn) => this.auction.events.hbaAuctionCreated.wait(fn);
		const { Auction } = window.relevantDigital;
		if (AmazonInterface.instance.adserverIntegration.useSetDisplayBids) {
			window.apstag.setDisplayBids();
		}
		const waitingRenderById = {};
		amazonAdUnitInstances.forEach((adUnitInstance, idx) => {
			const { slot } = adUnitInstance;
			const hbaBid = this.hbaBids[idx];
			const resp = byId[slot.getSlotElementId()];
			const {
				amzniid, amznbid, amznsz, mediaType,
			} = { ...resp?.targeting, ...resp };
			if (!AmazonInterface.instance.adserverIntegration.useSetDisplayBids) {
				slot.setTargeting?.('amzniid', amzniid);
				slot.setTargeting?.('amznbid', amznbid);
			}
			if (!amzniid) {
				if (amznbid === '1') { // 'bidInFlight', seems to indicate timeout
					waitHba(() => Auction.bidTimeout([hbaBid]));
				} else {
					waitHba(() => Auction.noBid(hbaBid));
				}
			} else {
				adUnitInstance.amazonBid = resp;
				const cpmHash = amznbid.split('_')[1] || amznbid; // Example: "amp_2hj32" => "2hj32"
				const [width, height] = (amznsz || '').split('x').map((s) => parseInt(s));
				const amzMult = Auction.getConvertedCpm(1, 'USD');
				Utils.assign(hbaBid, {
					mediaType: mediaType === 'video' ? 'video' : 'banner',
					cpm: 0, // amzCode will be converted to CPM by server instead
					amzCode: `${adserverId}_${cpmHash}`,
					...(amzMult && amzMult !== 1 && { amzMult }),
					currency: 'USD', // Currency is always USD
					width: width || 0,
					height: height || 0,
				});
				waitHba(() => Auction.bidResponse(hbaBid));
				// HACK: For Smart integration we need to hook the `apstag.renderImp` function
				// that is called from the Amazon creative script in order to detect an impression.
				if (AmazonInterface.instance.adserverIntegration.requiresRenderHook) {
					waitAmzniidRender(amzniid, () => {
						waitHba(() => Auction.bidWon(hbaBid));
					});
				} else {
					waitingRenderById[slot.getSlotElementId()] = hbaBid;
				}
			}
		});

		if (!Object.keys(waitingRenderById).length) {
			return;
		}

		// Let's wait for adserver response to check if the order-id
		// is any of the ones used for Amazon's price bucket line items.
		// If so => we got an impression
		adserver.waitEvent('slotRenderEnded', (ev) => {
			const { slot, isEmpty, orderId } = ev;
			const elmId = slot.getSlotElementId();
			const hbaBid = waitingRenderById[elmId];
			if (!hbaBid) {
				return true;
			}
			delete waitingRenderById[elmId];
			if (!isEmpty
				&& (orderIds.indexOf(`${orderId}`) >= 0 || adserver.isAmazonAd?.(ev))
				&& this.slotStillBelongsToAuction(elmId)) {
				waitHba(() => Auction.bidWon(hbaBid));
			}
			if (!Object.keys(waitingRenderById).length) {
				return false; // Stop listen to events
			}
			return true; // continue listen as we're waiting more responses
		});
	}

	run(doneCb) {
		const { amazonAdUnitInstances, pbRequester, onAmazonSlot } = this.auction;
		const { exchangeRates } = pbRequester;
		const slots = amazonAdUnitInstances.map((adUnitInstance) => {
			const { slot, adUnit, pbAdUnit } = adUnitInstance;
			const { floor: floorVal, currency } = adUnit.getBidFloor() || {};
			let floor;
			if (floorVal && exchangeRates[currency]) {
				floor = {
					value: Math.floor((exchangeRates.USD / exchangeRates[currency]) * floorVal * 100),
					currency: 'USD', // only supported currency, hence the conversion above
				};
			}
			const { video, banner } = pbAdUnit.mediaTypes;
			const isVideoOnly = !!video && !banner;
			const slotParams = { ...adUnit.amazonBid.params };
			SPECIAL_BID_PARAMS.forEach((param) => {
				delete slotParams[param];
			});
			const res = {
				slotID: slot.getSlotElementId(),
				slotName: getSlotName({ adUnit, slot }),
				sizes: adUnit.getPrebidSizes(true),
				slotParams,
				...(floor?.value && { floor }),
				...(isVideoOnly && {
					mediaType: 'video',
				}),
			};
			if (onAmazonSlot) {
				onAmazonSlot(res, adUnitInstance);
			}
			return res;
		});
		window.apstag.fetchBids({ slots }, (bids) => {
			this.onBidsBack({ bids });
			doneCb();
		});
	}
}

module.exports = AmazonAuction;
