const BidHandler = require('./bidderHandler');

const BidUtils = {
	getViewPort() {
		return {
			vWidth: window.innerWidth,
			vHeight: window.innerHeight,
		};
	},

	isDimensionValid({ dimension, viewport }) {
		const {
			minWidth, maxWidth, minHeight, maxHeight,
		} = dimension;
		const { vWidth, vHeight } = viewport;
		return (!minWidth || vWidth >= minWidth)
			&& (!maxWidth || vWidth < maxWidth)
			&& (!minHeight || vHeight >= minHeight)
			&& (!maxHeight || vHeight < maxHeight);
	},

	filterBids(params) {
		const { bids, validDimensions, auction } = params;
		if (!validDimensions.length) {
			return [];
		}
		const { geoCountry } = window.relevantDigital.getInstance();
		// skip geo filtering if it is not configured or if geoCountry is not available
		if (!auction.data.rlvBySsp || !geoCountry) {
			return bids.filter((bid) => !BidHandler.of(bid).isInvalid(bid, params));
		}
		return bids.filter((bid) => {
			const handler = BidHandler.of(bid);
			const { filterType, countries } = auction.data.rlvBySsp[handler.id] || {};
			if (filterType && countries && countries.includes(geoCountry) !== (filterType === 'include')) {
				return false;
			}
			return !handler.isInvalid(bid, params);
		});
	},
};

module.exports = BidUtils;
